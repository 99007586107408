import React, { useEffect } from "react"
import Layout from "./components/Layout";
import { Row, Col, Button } from "react-bootstrap";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import ArrowDown from "./components/ArrowDown";

export default function services() {
    const bgColorChange = () => {
        // Array containing colors 
        const colors = ['#FF4B00', '#87C2D6', '#048ECB', '#FFD726', '#E6E6E6', '#161616']; 
        // selecting random color 
        const random_color = colors[Math.floor(Math.random() * colors.length)]; 
        // select DOM element and add backgroundColor
        const x = document.querySelector('body'); 
        x.style.backgroundColor = random_color; 
    }
    return (
        <Layout>
            <Row id="banner" className="min-100 relative bg-inn"
            style={{backgroundPosition: '50% 100%', backgroundSize: '800%' }}>
                <Col className="col-md-8 align-left-bottom p-5 mt-5">
                <h1 className="site-title text-light text-shadow">
                    what i do.
                </h1>
                </Col>
                <Col className="offset-md-4"></Col>
            </Row>

            <Row className="bg-inn bg-lightblue p-4 min-100" style={{backgroundSize: '40%', backgroundPosition: '100% 0%'}}>
                <Col xl={6} className="text-light align-left min-50 p-5 bg-black">
                    <h6 className="text-uppercase drop-shadow">Graphic + UI + UX Design</h6>
                    <h1 className="h2 font-weight-bold drop-shadow text-shadow" style={{letterSpacing: '-2px'}}>I design websites, user interfaces, animations, and custom graphics. I design for web, social media, and print using Photoshop, Canva, Crello, Lunapic and other design tools. I create logos, posters, invitations, business cards, and source the perfect project-specific photos.</h1>
                    <ArrowDown stroke="#fff" />
                </Col>
                <Col xl={6} className="bg-inn bg-light min-50"></Col>
            </Row>

            {/* coding */}
            <Row className="">
                <Col xl={12} className="bg-inn bg-blue text-light align-left min-100 p-5"
                style={{backgroundSize: '1000%', backgroundPosition: '0% 100%'}}>
                    <h6 className="text-uppercase drop-shadow">Coding + Development</h6>
                    <h1 className="section-text font-weight-bold drop-shadow text-shadow" style={{letterSpacing: '-2px'}}>I build custom websites and develop stock themes with HTML5, CSS3, SASS, PHP, Javascript, jQuery, Bootstrap, Semantic UI, Wordpress and Webflow.</h1>
                    <ArrowDown stroke="#fff" />
                </Col>
            </Row>

            <Row className="bg-inn bg-lightblue p-4 min-100" style={{backgroundSize: '40%', backgroundPosition: '100% 0%'}}>
                <Col xl={6} className="bg-inn bg-lightblue min-50" style={{backgroundSize: '900%'}}></Col>
                <Col xl={6} className="align-left min-50 p-5 bg-light">
                    <h6 className="text-uppercase">Frameworks + Animation</h6>
                    <h1 className="h2 font-weight-bold" style={{letterSpacing: '-2px'}}>I also create single page applications and static websites with with React, Next.js, and Gatsby. I love web animation and use GSAP, AOS, Scroll Magic, Rellax.js, React-reveal, Framer, SWUP and more to create elegant user experiences.</h1>
                    <ArrowDown stroke="#000" />
                </Col>
            </Row>

            {/* writing */}
            <Row className="bg-inn bg-blue p-4 min-100" style={{backgroundSize: '700%', backgroundPosition: '100% 0%'}}>
                <Col xl={12} className="align-left min-50 p-5 bg-light bg-inn" style={{backgroundSize: '2000%', backgroundPosition: '10% 0%'}}>
                    <h6 className="text-uppercase">Copywriting + Editing</h6>
                    <h1 className="section-text font-weight-bold">
                        I write and edit copy for websites, social media, blog posts, articles, branding, and marketing in a wide variety of genres. I strive for fast moving, easy-to-read content & specialize in UX, SEO, and editing for web presentation.
                    </h1>
                    <AniLink cover bg="#000" direction="up" to="/projects">
                        <Button variant="outline-dark" className="mt-3">Read more</Button>
                    </AniLink>
                </Col>
            </Row>
        </Layout>
    )
}
